@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.mobileContainer{
    margin-top: -20px;
    @media screen and (min-width: 992px) {
        margin-top: 0;
    }
}

.errorScreen{
    height: 100%;
    width: 90vw;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 28px;
    @media screen and (min-width: 992px) {
        width: auto;
        top: 0;
        height: 300px;
    }
}

.errorText{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.infoContainer{
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    vertical-align: text-top;
    margin-left: 7px;
}

.iconsContainer{
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: -20px;
    @media screen and (min-width: 992px) {
        margin-top: 3px;
    }
}

.iconFnac{
    @media screen and (min-width: 992px) {
        width: 56px;
        height: auto;
    }
}

.iconCalendar{
    margin-top: 10px;
    @media screen and (min-width: 992px) {
        margin-top: 56px;
        width: 48px;
        height: auto;
}
}

.headerTitle{
    @extend .headerTitle;
    color: $color-white;
}
.subtitle{
    @extend .subtitle;
    @media screen and (min-width: 992px) {
        margin-top: 32px;
    }
}

.bodyText{
    @extend .bodyText;
    color: $color-dark-gray;
    @media screen and (min-width: 992px) {
        margin-top: 3px;
    }
}

.bodyTextFnac{  
    @extend .bodyText;
    font-size: 14px;
    @media screen and (min-width: 992px) {
        height: 30px;
        font-size: 20px;
    }
}

.bodyTextCalendar{
    @extend .bodyText;
    margin-top: 10px;
    height: 30px;
    font-size: 14px;
    @media screen and (min-width: 992px) {
        margin-top: 56px;
        font-size: 20px;
    }
}

.bodyTextTitle{
    @extend .bodyText;
    font-size: 16px;
    color: $color-white;
    margin-top: 5px;
    @media screen and (min-width: 992px) {
        font-size: 20px;
    }
}

.bodyTextStrong{
    @extend .regularText;
    font-weight: 700;
    color: $color-black;
}

.phoneNumber{
    @extend .titleBig;
    font-size: 24px;
}