// Global Colors

$color-institutional: #E1A925;
$color-institutional-darker: #B4871E;

$color-white: #FFF;
$color-light-gray: #E9E9E9;
$color-dark-gray: #BABABA;
$color-darkest-gray: #606060;
$color-black: #000;
$color-alert: #FF5929;

// Text Colors:

$color-text-regular: $color-dark-gray;
$color-text-strong: $color-black;
$color-text-subtitle: $color-institutional;
$color-button-label: $color-white;

// Button Colors:

$button-fill-color: $color-institutional;
$button-border-color: $color-institutional;
$button-color-hover: $color-institutional-darker;

