@import '../../styles/colors.scss';

.container{
    padding: 24px 0;
    height: 100%;
    min-height: 330px;
    flex-grow: 6;
    @media screen and (min-width: 992px) {
        left: 8%;
        position: relative;
        min-height: 500px;
    }
}

.logoContainer{
    z-index: 1;
    @media screen and (min-width: 992px) {
        position: absolute;
        top: 8%;
    }
}

.logo{
    width: 70px;
    height: 70px;
}

.textContainer{
    top: 48px;
    color: $color-white;
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    left: 5vw;
    right: 2vw;
    @media screen and (min-width: 992px) {
        top: -25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 58%;
        min-width: 450px;
    }
}

.background{
    background-image: url(../../assets/bg_fnac_parceiros.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 440px;
    @media screen and (min-width: 992px) {
        height: 100vh;
        width: 60vw;
    }
}
