@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.button{
    @extend .buttonText;
    margin-top: -5px;
    height: 50px;
    background-color: $button-fill-color;
    outline: inherit;
    border-radius: 6px;
    box-shadow: 0px 10px 22px rgba(225, 169, 37, 0.1);
    border: none;
    width: 100%;
    display: inherit;
    text-decoration: none; 
    vertical-align: middle;
}

.button:hover{
    background-color: $color-institutional-darker;
}

.buttonLabel{
    @extend .buttonText;
    text-align: center;
    position: relative;
    width: 100%;
    top: 9px;
}