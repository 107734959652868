@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700;900&display=swap');
@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.container{
    padding: 24px;
    height: 100vh;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
        width: 90%;
        left: 5%;
        position: absolute;
        top: 5%;
        height: 90%;
    }
}

.contactInfo{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 30%;
        position: relative;
        text-align: center;
    }
}

.errorTitle{
    font-family: 'Roboto', Arial;
    font-weight: 900;
    font-size: 30px;
    color: black;
    line-height: 117%;
    letter-spacing: -0.86px;
    @media screen and (min-width: 992px) {
        font-size: 40px;
        display: flex;
        flex-direction: column;
    }
    @media screen and (min-width: 1440px) {
        font-size: 46px;
    }
}

.errorMessage{
    @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 20%;
        position: relative;
    }
}

.button{
    @extend .buttonText;
    height: 50px;
    background-color: $button-fill-color;
    border: none;
    padding: 0;
    outline: inherit;
    border-radius: 6px;
    box-shadow: 0px 10px 22px rgba(225, 169, 37, 0.1);
    border: 1px solid $button-border-color;
    width: 100%;
    height: 50px;
    display: inherit;
    text-decoration: none;
    margin: 30px 0 20px 0;    
}

.button:hover{
    background-color: $color-institutional-darker;
}

.buttonLabel{
    @extend .buttonText;
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: 5px;
}