@import '../../styles/colors.scss';

.container{
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px 10px 0 0;
    align-items: center;
    margin-top: -10px;
    background-color: $color-white;
    min-height: 400px;
    flex-grow: 4;
    @media screen and (min-width: 992px) {
        margin-top: 0;
        border-radius: 0;
        padding: 0;
        height: 100%;
        width: 40vw;
        position: relative;
        display: flex;
        flex-direction: column;
    }
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    width: auto;
    position: relative;
    align-items: center;
    top: 10px;
    @media screen and (min-width: 992px) {
        top: 20px;
        margin: 0 10% 0 10%;
        height: 100vh;
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }    
}

.legend{
    margin-bottom: 10px;
    height: 7px;
    width: 91px;
}

.legendAnchor{
    width: min-content;
    z-index: 1;
}