@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

p{
    font-family: 'Roboto', Arial;
}

html, body {
    height: 100vh;
    width: 100vw;
}

.App{
    width: 100vw;
    height: 100vh;
}

.pointer{
    cursor:pointer;
    opacity: 50%;
}

.selected{
    opacity: 100%;
    @media screen and (min-width: 992px) {
        color: #E1A725;
        opacity: 100%;
    }
}

.groupedText{
    margin-right: 5px;
    @media screen and (min-width: 992px) {
        margin-right: 15px;
    }
}