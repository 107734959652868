@import '../../styles/typography.scss';

.customerInfoContainer{
    height: 100%;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @media screen and (min-width: 992px) {
        height: 300px;
        width: 70%;
        min-width: 400px;
        top: 25%;
        position: relative;
    }
}

.toggles{
    @extend .regularText;
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-around;
    top: -50px;
    position: relative;
    color: white;
    font-size: 22px;
    @media screen and (min-width: 992px) {
        top: -75px;
        width: 400px;
        font-size: 24px;
        top: 0;
        color: black;
    }
}

.qrCode{
    width: 150px;
    height: auto;
    min-height: 150px;
    margin-bottom: 20px;
    @media screen and (min-width: 992px) {
        min-height: 300px;
        width: 300px;
    }
}

.barCode{
    width: 300px;
    height: auto;
    @media screen and (min-width: 992px) {
        height: auto;
        width: 400px;
    }
}

.cardInfo{
    display: flex;
    align-items: center;
}

.cardIcon{
    width: 20px;
    height: auto;
    margin-right: 5px;
    @media screen and (min-width: 992px) {
        width: 40px;
    }
}

.pan{
    font-size: 20px;
    font-weight: 700;
    align-items: center;
    margin: 0;
    letter-spacing: 0.5px;
    @media screen and (min-width: 992px) {
        min-width: 400px;
        font-size: 36px;
    }
}

