@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.formsContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 40px;
    align-items: center;
    @media screen and (min-width: 992px) {
        height: 300px;
        width: 20vw;
        min-width: 276px;
        max-width: 500px;
    }
}

.form{
    position: relative;
    width: 83vw;
    min-height: 80px;
    @media screen and (min-width: 992px) {
        width: 100%;
    }
}

.otpForm{
    @extend .form;
    top: 40px;
}

.input{
    outline: 0;
    width: 100%;
    border-width: 0 0 1px;
    border-color: $color-light-gray;
    font-size: 20px;
    color: $color-text-strong;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    position: relative;
    background: none;
    @supports (-webkit-touch-callout: none) {
        &::-webkit-date-and-time-value {
            margin-top: 0px;
            text-align: left;
            width: 100%;
            display:block;
            margin-left: -10px;
        }
        &[type="text"]{
            padding-left: 0;
        }
        -webkit-appearance: none;
        border-radius: 0;
    }
    &:required:invalid::-webkit-datetime-edit {
        color: transparent;
    }
    &:focus::-webkit-datetime-edit {
        color: black !important;
    } 
}

.inputLabel{
    @extend .regularText;
    position: absolute;
    top: -24px;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(25px);
    transition: ease-in 0.05s;
}

.inputLabelDate{
    @extend .inputLabel;
    @supports (-webkit-touch-callout: none) {
        top: -39px;
    }
}


.input:focus + label{
    transform: translateY(0);
    font-size: 14px;
    transition: ease-in 0.05s;    
    @supports (-webkit-touch-callout: none) {
        top: -20px;
    }
}

.input:valid + label{
    transform: translateY(0);
    font-size: 14px;
    @supports (-webkit-touch-callout: none) {
        top: -20px;
    }
}

.helpButton{
    @extend .bodyText;
    top: 10px;
    text-decoration: none;
    color: $color-institutional;
    position: relative;
    @media screen and (min-width: 992px) {
        top: 22px;
    }
}

.helpButton:hover {
    color: $color-institutional-darker;
}

.title{
    @extend .headerTitle;
    color: $color-white;
    font-weight: 700;
    margin-bottom: 16px;
}

.subtitle{
    @extend .subtitle;
    margin-bottom: 16px;
    @media screen and (min-width: 992px) {
        margin-bottom: 24px;
    }
}

.body{
    @extend .bodyText;
    color: $color-white;
    @media screen and (min-width: 992px) {
        margin-top: 16px;
    }
}

.bodyStrongWhite{
    @extend .bodyText;
    color: $color-white;
    font-weight: 900;
}

.bodyStrong{
    @extend .bodyText;
    font-weight: 700;
}

.bodyStrongWithMargin{
    @extend .bodyStrong;
    margin-bottom: -5px;
}

.errorMessage{
    @extend .auxiliaryText;
    animation: fadeIn ease 0.1s;
    color: #FF5929;
    position: absolute;
    top: -35px;
}
