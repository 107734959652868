@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import './colors.scss';

@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}  

.headerTitle{
    font-family: 'Roboto', Arial;
    font-weight: 900;
    font-size: 30px;
    color: $color-text-regular;
    line-height: 117%;
    letter-spacing: -0.86px;
    @media screen and (min-width: 992px) {
        font-size: 40px;
    }
    @media screen and (min-width: 1440px) {
        font-size: 46px;
    }
}

.subtitle{
    font-family: Roboto;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: $color-institutional;
    line-height: 110%;
    letter-spacing: 0.5px;
    @media screen and (min-width: 992px) {
        font-size: 20px;
    }
    @media screen and (min-width: 1440px) {
        font-size: 24px;
    }
}

.titleBig{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: $color-text-strong;
    line-height: 26px;
    letter-spacing: 0.5px;
    @media screen and (min-width: 992px) {
        font-size: 20px;
    }
    @media screen and (min-width: 1440px) {
        font-size: 24px;
    }
}

.titleSmall{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: $color-text-strong;
    line-height: auto;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.buttonText{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: $color-button-label;
    line-height: auto;
    letter-spacing: 0.16px;
}

.regularText{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: $color-text-regular;
    line-height: auto;
    letter-spacing: 0;
}

.bodyText{
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: $color-text-strong;
    line-height: 130%;
    letter-spacing: 0;
}

.auxiliaryText{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $color-text-strong;
    line-height: 130%;
    letter-spacing: 0;
}



