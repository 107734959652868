@import "../../styles/typography.scss";

.errorScreen{
    height: 100%;
    min-height: 300px;
    width: 90vw;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    top: 28px;
    @media screen and (min-width: 992px) {
        width: auto;
        height: auto;
        margin: 0 150px 0 150px;
    }
}

.errorText{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 992px) {
        min-width: 400px;
    }
}

.headerTitle{
    @extend .headerTitle;
    color: $color-white;
}

.phoneNumber{
    @extend .headerTitle;
    color: $color-black;
}

.body{
    @extend .bodyText;
    color: $color-white;
    @media screen and (min-width: 992px) {
        margin-top: 16px;
    }
}

.regular{
    @extend .bodyText;
    color: $color-text-regular;
}